<template>
  <div>
  <div class="card shadow mb-4">
    <div class="card-body">
      <canvas id="scor-chart"></canvas>
    </div>
  </div>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  name: 'ScorChart',
  props:{
    lineLabels:{
      type: Array,
      default: () => []
    },
    lineData:{
      type: Array,
      default: () => []
    },
    lineBackgroundColors:{
      type: Array,
      default: () => []
    }
  },
  watch:{
    lineLabels(newValue){
      console.log(newValue);
      // this.greutateChartData.data.labels = newValue;

      // this.chartCTX.data.labels.push(newValue);
      // this.chartCTX.update();

      // this.renderChart();
    },
     lineData(newValue){
      console.log(newValue);

      // this.chartCTX.data.datasets.forEach((dataset) => {
      //   dataset.data.push(newValue);
      // });

      // this.chartCTX.update();

      // this.greutateChartData.data.datasets[0].data = newValue;
      // this.renderChart();
    }
  },
  data(){
    return{

      chartCTX : null,
      aspectRatioChart: 1,
      formattedLineLabels: [],
      greutateChartData: {
        type: "horizontalBar",
        data: {
          labels: this.lineLabels,
          datasets: [
            {
              label: "Greutate ",
              data: this.lineData,
              // borderColor: "#a6d4f7",
              backgroundColor: this.lineBackgroundColors,
              // borderWidth: 230,
              // barPercentage: 1,
              // minBarLength: 100,
            }
          ]
        },
        options: {
          legend:{
            display:false,
          },
          layout:{
            padding:{
              left:0,
              right:0,
              top:25,
              bottom:50
            }
          },
          responsive: true,
          // maintainAspectRatio: true,
          aspectRatio: 0.4,
          scales: {
            yAxes: [{
              gridLines: {
                // display:false
              },
              ticks:{
                fontSize: 16,
                mirror: true,
                padding: -20,
                z:3,
                callback: function(value){
                  return value;
                }
              },
            }],
            xAxes: [{
              gridLines: {
                // display:false
              },
              position:"top",
              ticks:{
                callback: function(value){
                    return value + " kg";
                }
              },
            }]
          }
        }
      }
    }
  },
  mounted(){
    this.renderChart();
  },

  methods:{

    renderChart(){
      const ctx = document.getElementById('scor-chart');

      this.lineLabels.forEach( (val, index) => {
        let newVal = val + " ("+this.lineData[index]+" kg)";
        this.formattedLineLabels.push(newVal);
      });

      if(document.documentElement.clientWidth < 1000){
        this.greutateChartData.options.aspectRatio = 0.3;
      } else {
        this.greutateChartData.options.aspectRatio = 1;
      }

      this.greutateChartData.data.labels = this.formattedLineLabels;
      

      this.chartCTX = new Chart(ctx, this.greutateChartData);
    }

  }
}
</script>
<style scoped>
#scor-chart{
  /* max-width:500px; */
}
</style>