<template>

 <scorChart  v-if="lineLabels.length"  :lineLabels="lineLabels" :lineData="lineData" :lineBackgroundColors="lineBackgroundColors" />

 <div class="card shadow mb-4">
    <div class="card-body allow-overflow">
        <table v-if="scoruriFinale.length">
          <thead>
            <tr class="bold">
              <th>Echipa</th>
              <th>Sector</th>
              <th v-for="index in 10" :key="index">
                {{ index }}
              </th>
              <th>medie BEST</th>
              <th>nr total pesti</th>
              <th>locul pe sector</th>
              <th @click="sortGeneral">
                <div class="d-flex align-items-center justify-content-between cg_sort ">
                  clasament general <div class="fa-1x text-primary"><i class="fas fa-sort"></i></div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(scorEchipa, indexScor) in scoruriFinale" :key="indexScor" :style="getInlineStyle(scorEchipa, indexScor)">
              <th><a :title="scorEchipa.nume">{{scorEchipa.nume}}</a></th>
              <td>{{scorEchipa.sector}}{{scorEchipa.stand}}</td>
              <td v-for=" (scorFinal,index) in scorEchipa.inregistrari" :key="index">
                {{scorFinal}} <small>kg</small>
              </td>
              <td>{{ scorEchipa.medieBest}}  <small>kg</small></td>
              <td>{{ scorEchipa.totalPesti }}</td>
              <td>{{ scorEchipa.locSector }}</td>
              <td>{{ scorEchipa.locGeneral }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else>
          <div class="fa-1x text-center text-primary mb-4 mt-4">
          Se incarca &nbsp; <i class="fas fa-fish fa-spin"></i>
          </div>
        </div>
    </div>
  </div>

</template>
<script>
import PescariDataService from "../services/PescariDataService";
import scorChart from "./ScorChart";

export default {
  name: "scoruri",
  props: ["concurs"],
  components: { 
    scorChart,
  },
  data() {
    return {
      currentID: this.$route.params.idConcurs,
      scoruri: null,
      scoruriFinale: [],
      data: null,
      peSectoare: [],
      peGeneral: [],
      scoruriFinaleCopy: [],
      generalSorted: false,
      lineLabels:[],
      lineData:[],
      lineBackgroundColors:[],
      backgroundColors:[
        [
          "#cae5fa","#a6d4f7",
        ],
        [
          "#fafaaa","#f5f567",
        ],
        [
          "#a7fa9d","#75f567",
        ],
         [
          "#dddfeb","#d1d3e2",
        ]
      ]
    };
  },
  methods: {
    sortGeneral(){
      if(this.generalSorted){
        this.scoruriFinale = [...this.scoruriFinaleCopy];
      } else {
        this.scoruriFinale.sort( (a,b) => (a.medieBest < b.medieBest) ? 1 : -1 );
      }

      let lineDataSorted = [];
      let lineLabelsSorted = [];
      let lineBackgroundsSorted = [];
      for( const property in this.scoruriFinale){
        lineDataSorted.push( this.scoruriFinale[property].medieBest) ;
        lineLabelsSorted.push( this.scoruriFinale[property].nume) ;
        lineBackgroundsSorted.push( this.getColorForSector(this.scoruriFinale[property].sector, 0) );
      }

      this.lineData = lineDataSorted;
      this.lineLabels = lineLabelsSorted;
      this.lineBackgroundColors = lineBackgroundsSorted;

      this.generalSorted = !this.generalSorted;

    },
    getColorForSector( sectorEchipa , colorIndex  ){
      let sectorIndex = 0;
      this.echipeSectoare.forEach( (sector, index) => {
        if( sectorEchipa === sector){
          sectorIndex = index;
        }
      });

      if (sectorIndex >=  this.backgroundColors.length){
        sectorIndex = sectorIndex % this.backgroundColors.length;
      }

      return this.backgroundColors[sectorIndex][colorIndex];
    },
    getInlineStyle(echipa, indexScor){
      return 'background-color:'+ this.getColorForSector(echipa.sector,  indexScor % 2);
    },
    
    async getScoruri(){
      this.scoruri = await PescariDataService.getEchipe(this.currentID);
      for (const echipa of this.scoruri) {
        let inregistrariEchipa = await PescariDataService.getInregistrariEchipa(this.currentID, echipa.id);
        echipa.inregistrari = inregistrariEchipa;
      }
      this.prepareScoruri();

      console.log( this.scoruriFinale );
    },
    prepareScoruri(){
      this.scoruri.forEach( scor => {
        let inregistrari = [];
        scor.inregistrari.forEach( inregistrare =>{
          inregistrari.push(inregistrare.greutate);
        });

        let totalPesti = inregistrari.length;

        if(scor.inregistrari.length < 10){
          let dif = 10 - scor.inregistrari.length;
          for (let step = 0; step < dif; step++) {
            inregistrari.push("0");
          }
        }
        inregistrari.sort((a,b)=>b-a);
        if (inregistrari.length > 10) inregistrari.length = 10;

        let medieBest = 0;

        inregistrari.forEach( inregistrare => {
          medieBest += parseInt(inregistrare);
        });
        medieBest = medieBest / inregistrari.length;


        this.scoruriFinale.push({
          id: scor.id,
          nume: scor.echipa.nume,
          sector: scor.echipa.sector,
          stand: scor.echipa.stand,
          inregistrari: inregistrari,
          medieBest: medieBest,
          totalPesti: totalPesti,
        });

      });

      this.scoruriFinale.sort((a, b) => (a.stand < b.stand) ? 1 : -1)
      this.scoruriFinale.sort((a, b) => (a.sector > b.sector) ? 1 : -1)

      this.scoruriFinale.forEach( (scor, index) => {
        if( !this.peSectoare[ scor.sector] ){
          this.peSectoare[ scor.sector ] = [];
        }
        this.peSectoare[ scor.sector ].push({
          medieBest: scor.medieBest,
          key: index,
          id: scor.id,
        });

        this.peGeneral.push({
          medieBest: scor.medieBest,
          key: index,
          id: scor.id,
        });
      });
 
      for( const property in this.peSectoare){
        let scor = this.peSectoare[property]; 
        scor.sort( (a,b) => (a.medieBest < b.medieBest) ? 1 : -1 );
      }

      this.peGeneral.sort( (a,b) => (a.medieBest < b.medieBest) ? 1 : -1 );

      for( const property in this.scoruriFinale){
        for (const prop in this.peSectoare){
            this.peSectoare[prop].forEach( (rank, index) => {
              if( rank.id == this.scoruriFinale[property].id ) {
                this.scoruriFinale[property].locSector = index + 1;
              }
            });
        }

        this.peGeneral.forEach( (rank, index) => {
          if ( rank.id == this.scoruriFinale[property].id ) {
            this.scoruriFinale[property].locGeneral = index + 1;
          }
        });
        
      }

      this.scoruriFinaleCopy = [...this.scoruriFinale];





      // fac un array de sectoare ca sa pot sa le unific prin index, astfel incat ei le pot da orice denumire vor in backend, dar eu voi lucra doar pe index
      this.echipeSectoare = [];
      for( const property in this.scoruriFinale){
        this.echipeSectoare.push( this.scoruriFinale[property].sector );
      }
      this.echipeSectoare = [...new Set(this.echipeSectoare)];



      let orderScoruriFinale = [...this.scoruriFinale];
      orderScoruriFinale.sort( (a,b) => (a.medieBest < b.medieBest) ? 1 : -1 );
      for( const property in orderScoruriFinale){
        this.lineData.push( orderScoruriFinale[property].medieBest) ;
        this.lineLabels.push( orderScoruriFinale[property].nume) ;
        this.lineBackgroundColors.push( this.getColorForSector(orderScoruriFinale[property].sector, 0) );
      }


      
    },
  },
  mounted() {
      this.getScoruri();
  },
};
</script>
<style scoped>
@media screen and (max-width:1000px){
  body table th,
  body table td{
    font-size:0.7rem;
    font-weight:normal;
    padding:8px;
  }
  body tbody th{
    max-width:100px;
  }
}
tbody th{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width:150px;
}
body table th,
body table td{
  padding:8px;
}
table{
  width:100%;
  border:1px solid #e9e9e9;
}
table td{
  border:1px solid #e9e9e9;
  padding:10px 20px;
}
.bold th,
.bold td{
  font-weight:600;
}
.allow-overflow{
  overflow:scroll;
  max-width:100%;
  max-height:60vh;
}
td{
  white-space:nowrap;
}

thead th {
  position: -webkit-sticky; 
  position: sticky;
  top: 0;
}

tbody th {
  position: -webkit-sticky; 
  position: sticky;
  left: 0;
}
thead th:first-child {
  left: 0;
  z-index: 2;
}

thead th {
  background: #FFF;
  z-index: 1;
}

tbody th {
  background: #FFF;
  border-right: 1px solid #CCC;
  box-shadow: 1px 0 0 0 #ccc;
}

.card-body.allow-overflow{
  padding:0px;
}
table {
  border-collapse: collapse;
} 
.cg_sort{
  cursor:pointer;
}
.cg_sort > div{
  margin-left:5px;
}

</style>