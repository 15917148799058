<template>

 <div class="container-fluid max-width-container" v-if="currentConcurs">

  <div class="d-sm-flex1 align-items-center justify-content-between mb-4 top_heading">
    <h1 class="h3 mb-0 text-gray-800">{{ currentConcurs.concurs.nume }} {{ currentConcurs.concurs.data }}</h1>
    <p>{{ currentConcurs.concurs.locatie }} - {{ currentConcurs.concurs.data }}</p>
  </div>

  <scoruri :concurs="currentID" />

</div>

</template>
<script>
import PescariDataService from "../services/PescariDataService";
import Scoruri from "./Scoruri";

export default {
  name: "concurs-public",
  components: { Scoruri },
  data() {
    return {
      currentConcurs: null,
      currentID: this.$route.params.idConcurs,
    };
  },

  methods: {
  },
  mounted() {
    PescariDataService.getConcurs(this.currentID).then( 
      qSnap => {
        this.currentConcurs = qSnap;
      }
    );
  },
};
</script>

<style scoped>
.top_heading{
  margin-top:45px;
}
.top_heading > span{
  font-size:1rem;
}
.max-width-container{
  max-width:1000px;
}
</style>
<style>
body .navbar,
body .navbar-nav{
  display:none!important;
}
.wrap_form_group,
.edit-form form{
  margin-bottom:30px;
  padding:30px;
  background-color:#f5f5f5;
}
.flex-wrap{
  display:flex;
  justify-content: space-between;
}
.color-red{
  color:red;
}
.flex-wrap > div{
  font-size:16px;
}
.flex-wrap > div a{
  margin-left:20px;
}
.container-fluid .container-fluid{
  padding-left:0px;
  padding-right:0px;
}
</style>